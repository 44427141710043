module controllers {
    export module master {
        

        interface IProductCustomerReferencesScope extends ng.IScope {
        }

        enum showEnum {
            All = 0,
            NoCustomerReferences = 1,
            WithCustomerReferences = 2,
        }

        export class productCustomerReferencesCtrl {
            static $inject = ["$scope", "$rootScope", "generalService", "$q", "bsLoadingOverlayService", "customerService", "entityService", "skuService",
                "sKUCustomerService", "$timeout"];
            
            selectedCustomer: interfaces.applicationcore.IDropdownModel;
            selectedEntity: interfaces.applicationcore.IDropdownModel;
            selectedShow: showEnum = showEnum.All;
            skuCode: string;
            description: string;

            gridApi: uiGrid.IGridApi;

            IsLoading: boolean = false;
            currentPage: number = 1;
            currentPageTest: number = 1;
            pageSize: number = 10;

            searchAccordian: boolean = false;

            constructor(private $scope: IProductCustomerReferencesScope, private $rootScope: interfaces.applicationcore.IRootScope, 
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private customerService: interfaces.master.ICustomerService,
                private entityService: interfaces.applicationcore.IEntityService,
                private skuService: interfaces.master.ISKUService,
                private sKUCustomerService: interfaces.master.ISKUCustomerService,
                private $timeout: ng.ITimeoutService) {
                
            }

            filterForm_submit() {
                this.searchAccordian = false;
                this.loadSKUCustomers();
            }

            showAll_click() {
                this.searchAccordian = false;
                this.selectedCustomer = undefined;
                this.selectedEntity = undefined;
                this.skuCode = undefined;
                this.description = undefined;
                this.selectedShow = 0;

                this.loadSKUCustomers();
            }

            submit_click() {
                this.$timeout(() => {
                    var dirtyRows = this.gridApi.rowEdit.getDirtyRows();

                    if (dirtyRows.length > 0) {
                        this.IsLoading = true;
                        var entityList = _.map(dirtyRows, (value) => { return value.entity });

                        this.sKUCustomerService.saveList(entityList).then((msgHandler: interfaces.applicationcore.IMessageHandler) => {
                            this.IsLoading = false;
                            this.generalService.displayMessageHandler(msgHandler);

                            if (!msgHandler.HasErrorMessage) {
                                this.gridApi.rowEdit.setRowsClean(entityList);
                                this.loadSKUCustomers();
                            }

                        }, (errorResponse) => {
                            this.IsLoading = false;
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                    }
                });
                
            }
            loadCustomers(searchText: string) {
                return this.customerService.getDropdownList(0, searchText).query(() => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(() => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }
            
            loadSKUs(searchText: string) {
                return this.skuService.getDropdownList(0, searchText).query(() => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadSKUCustomers() {
                var customerId = this.selectedCustomer ? this.selectedCustomer.Id : 0;
                var entityId = this.selectedEntity ? this.selectedEntity.Id : 0;

                this.IsLoading = true;
                return this.sKUCustomerService.getAllSKU(entityId, customerId, this.skuCode, this.description, this.selectedShow, this.pageSize, this.currentPage).query((result: ng.resource.IResourceArray<interfaces.master.ISKUCustomer>) => {
                    this.IsLoading = false;
                    this.gvwProdCusRef.data = result;
                    if (result[0]) {
                        this.gvwProdCusRef.totalItems = result[0].NumberRecords;
                    } else {
                        this.gvwProdCusRef.totalItems = 0;
                    }
                }, (errorResponse) => {
                    this.IsLoading = false;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });
            }

            public gvwProdCusRef: uiGrid.IGridOptions = {
                data: [],
                enableSorting: false,
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [10, 20, 50, 100],
                paginationPageSize: this.pageSize,
                useExternalPagination: true,
                enablePagination: true,
                enablePaginationControls: true,
                paginationCurrentPage: this.currentPageTest,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ENTITYCODE",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 90,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    enableColumnMenu: false
                }, {
                        name: "SKUCODE",
                        displayName: "SKU",
                        field: "SKU",
                        width: 175,
                        enableColumnMenu: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: (entity) => { return entity.row.entity.IsNew; },
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Code}}</div>`,
                        editableCellTemplate: `<form name="inputForm" ng-if="row.entity.IsNew">
                                                <gts-dropdown ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.prodCusRefCtrl.loadSKUs(searchText)" required="true" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                            </form>`
                    },{
                        name: "SKUDESC",
                        displayName: "Description",
                        field: "SKU.Description",
                        width: 250,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        enableColumnMenu: false
                    }, {
                        name: "CUSTOMER",
                        displayName: "*Customer",
                        field: "Customer",
                        width: 250,
                        enableColumnMenu: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                            <form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.prodCusRefCtrl.loadCustomers(searchText)" required="true" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                            </form>`
                    }, {
                        name: "DESC",
                        displayName: "Description",
                        field: "Description",
                        width: 250,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        enableColumnMenu: false,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor maxlength="120" />
                                        </p>
                                    </form>`
                    }, {
                        name: "REF1",
                        displayName: "Reference 1",
                        field: "Reference1",
                        width: 150,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        enableColumnMenu: false,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor maxlength="30" />
                                        </p>
                                    </form>`
                    }, {
                        name: "REF2",
                        displayName: "Reference 2",
                        field: "Reference2",
                        width: 150,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        enableColumnMenu: false,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor maxlength="30" />
                                        </p>
                                    </form>`
                    }, {
                        name: "REF3",
                        displayName: "Reference 3",
                        field: "Reference3",
                        width: 150,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        enableColumnMenu: false,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor maxlength="30" />
                                        </p>
                                    </form>`
                    }, {
                        name: "REF4",
                        displayName: "Reference 4",
                        field: "Reference4",
                        width: 150,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        enableColumnMenu: false,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor maxlength="30" />
                                        </p>
                                    </form>`
                    }, {
                        name: "REF5",
                        displayName: "Reference 5",
                        field: "Reference5",
                        width: 150,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        enableColumnMenu: false,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor maxlength="30" />
                                        </p>
                                    </form>`
                    }, {
                        name: "ACTIVE",
                        displayName: "Is Active",
                        field: "IsActive",
                        width: 75,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        enableColumnMenu: false,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor>
                                        </p>
                                    </form>`
                    }]
            };

            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.loadSKUCustomers();

                this.gridApi.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.gridApi.selection.selectRow(gridSelection.row.entity);
                });

                this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => {
                    this.pageChanged(newPage, pageSize);
                });
            }

            pageChanged(newPage: number, pageSize: number) {
                var dirtyRows = this.gridApi.rowEdit.getDirtyRows();

                if (dirtyRows.length > 0) {
                    this.IsLoading = true;
                    var entityList = _.map(dirtyRows, (value) => { return value.entity });

                    this.sKUCustomerService.saveList(entityList).then((msgHandler: interfaces.applicationcore.IMessageHandler) => {
                        this.IsLoading = false;
                        this.generalService.displayMessageHandler(msgHandler);

                        if (!msgHandler.HasErrorMessage) {
                            this.gridApi.rowEdit.setRowsClean(entityList);
                            this.changePage(newPage, pageSize);
                        }

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    });
                } else {
                    this.changePage(newPage, pageSize);
                }
            }


            changePage(newPage: number, pageSize: number) {
                this.currentPage = newPage;
                this.pageSize = pageSize;
                this.loadSKUCustomers();
            }

            addNew_click() {
                (<Array<any>>(this.gvwProdCusRef.data)).splice(0, 0, { Customer: this.selectedCustomer ? this.selectedCustomer : undefined, IsNew: true });
            }
        }

        angular.module("app").controller("productCustomerReferencesCtrl", controllers.master.productCustomerReferencesCtrl);
    }
}